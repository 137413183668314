<template>
  <div class="main-container">
    <div class="landing-main-wrapper">
      <video autoplay muted class="full-screen-video" ref="video">
        <source src="../assets/main.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div class="app-container">
        <div class="app-title">OPT Finance</div>
        <div class="app-title-sub">#1 DEX Aggregator on WEMIX3.0</div>
        <div class="join">
          <a href="https://swap.opt.finance/">
            <button class="join-button">LAUNCH APP</button>
          </a>
        </div>
        <div class="info">
          <a href="https://docs.opt.finance/en/overview/?version=latest">
            <button class="book-button">
              <img src="../assets/book.svg" class="book-logo" />
            </button>
          </a>
          <a href="https://github.com/opt-finance">
            <button class="github-button">
              <img src="../assets/github.svg" class="github-logo" />
            </button>
          </a>
          <a href="https://t.me/OPTFinanceANN">
            <button class="telegram-button">
              <img src="../assets/telegram.svg" class="telegram-logo" />
            </button>
          </a>
          <a href="https://twitter.com/OPTFinance">
            <button class="twitter-button">
              <img src="../assets/twitter.svg" class="twitter-logo" />
            </button>
          </a>
          <a href="https://medium.com/@optfi">
            <button class="medium-button">
              <img src="../assets/medium.svg" class="medium-logo" />
            </button>
          </a>
          <a href="mailto:optfi@proton.me">
            <button class="email-button">
              <img src="../assets/email.svg" class="email-logo" />
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "MainPage",
  mounted() {
    this.$refs.video.currentTime = 1.0; // Set the starting point to 1 seconds
    this.$refs.video.playbackRate = 0.7; // Set the playback rate to 0.7x
  },
};
</script>
  
<style>
.main-container {
  position: relative;
  overflow: hidden;
}

.landing-main-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.full-screen-video {
  width: 99vw;
  height: 90vh;
  object-fit: cover;
}

@keyframes fadeIn {
  from {
    opacity: 0.1;
  }

  to {
    opacity: 1;
  }
}

.app-container {
  position: absolute;
  /* font-weight: 700; */
  top: 50%;
  right: 70%;
  transform: translate(50%, -50%);
  color: black;
  text-align: center;

  animation-name: fadeIn;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

.app-title {
  /* transform: translateX(-6px); */
  font-weight: 700;
  text-align: left;
  font-size: 80px;
  margin-bottom: 20px;
  /* text-shadow: rgba(255, 255, 255, 0.5) 0px -1px 2px, rgb(0, 0, 0) 2px 2px 2px; */
}

.app-title-sub {
  font-weight: 500;
  text-align: left;
  font-size: 40px;
  margin-bottom: 50px;
  /* text-shadow: rgba(255, 255, 255, 0.5) 0px -1px 2px, rgb(0, 0, 0) 1.5px 1.5px 1.5px; */
}

.join {
  /* transform: translateX(-14px); */
  text-align: left;
}

.info {
  /* transform: translateX(-6px); */
  text-align: left;
}

.join-button {
  padding: 15px 20px;
  margin-bottom: 5px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 35px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 260px;
  height: 70px;
  font-size: 30px;
  background-color: black;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 40px;
}

.join-button-disabled {
  cursor: default;
  background-color: grey;
}

.join-button:hover {
  background-color: grey;
}

.join-button-disabled:hover {
  background-color: grey;
}

.book-logo,
.github-logo,
.telegram-logo,
.twitter-logo,
.medium-logo,
.email-logo {
  width: 40px;
  margin-right: 20px;
  cursor: pointer;
}

.book-button,
.github-button,
.telegram-button,
.twitter-button,
.medium-button,
.email-button {
  height: 40;
  background-color: transparent;
  border: transparent;
}

@media (max-width: 530px) {
  .full-screen-video {
    display: none;
  }

  .landing-main-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
  }

  .app-container {
    position: relative;
    top: 0%;
    right: 0%;
    transform: translate(0%, 0%);
  }

  .app-title,
  .app-title-sub,
  .join,
  .info {
    transform: translateX(0px);
    text-align: center;
  }

  .book-logo,
  .github-logo,
  .telegram-logo,
  .twitter-logo,
  .medium-logo,
  .email-logo {
    margin-left: 4px;
    margin-right: 4px;
  }
}
</style>
